.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    margin: 0 20% 0 20%;
    .title-bar {
        width: 100%;
        font-size: 50px;
        margin: 30px 0 30px 0;
    }
    .app-body {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        margin: 20px 0 20px 0;
    }
    p {
        margin: 0 0 20px 0;
        text-align: left;
        font-size: 25px;
        font-weight: 400;
    }
    div {
        font-size: 15px;
        font-weight: 300;
        text-align: left;
    }
    button {
        border-radius: 4px;
        border: 1px solid grey;
        background: rgba(220,220,220,0.2);
    }
    .placeholder {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .snackbar {
        min-width: 300px;
        background-color: #333;
        opacity: 0.8;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        padding: 16px;
        position: fixed;
        z-index: 1;
        bottom: 30px
    }

}

@media only screen and (max-width: 550px) {
    .App {
        margin: 0 20px 0 20px;
        .title-bar {
            font-size: 40px;
        }
        p {
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 1025px) {
    .App {
        .app-body {
            flex-direction: column;
            .result-list {
                margin: 0 0 20px 0;
            }
            .nominations {
                margin: 0 0 20px 0;
            }
        }
    }
}