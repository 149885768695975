.result-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 20px;
    background: white;
    margin-right: 10px;
    box-shadow: 1px 3px 10px grey;
    border-radius: 5px;
    min-height: 560px;
    box-sizing: border-box;
    .loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid grey;
        height: 5vw;
        width: 5vw;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .list-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}