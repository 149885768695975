.nominations {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 20px;
    background: white;
    margin-left: 10px;
    box-shadow: 1px 3px 10px grey;
    border-radius: 5px;
    min-height: 284px;
    box-sizing: border-box;
    .title {
        display: flex;
        flex-direction: row;
        .text {
            flex-grow: 1;
        }
        .nomination-count {
            opacity: 0.3;
        }
    }
    
}